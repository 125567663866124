<script src="../app.js"></script>
<template>
    <div>
        <div class="area-map_all d-flex flex-row-reverse justify-content-between align-content-end mx-auto mb-5 pb-5">
            <div class="area-map_1 align-self-end">
                <div class="d-flex flex-column">
                    <a href="./shop-list.php?area=hokkaido" class="all_hokkaido">
                        <img src="assets/img/shop/all_hokkaido_sp.png" class="d-lg-none" alt="北海道・東北">
                        <img src="assets/img/shop/all_hokkaido_pc.png" class="d-none d-lg-block" alt="北海道・東北">
                    </a>
                    <a href="./shop-list.php?area=kento">
                        <img src="assets/img/shop/all_kanto_sp.png" class="area-map_kanto d-lg-none" alt="関東">
                        <img src="assets/img/shop/all_kanto_pc.png" class="area-map_kanto d-none d-lg-block" alt="関東">
                    </a>
                </div>
            </div>
            <div class="area-map_2 align-self-end">
                <div>
                    <a href="./shop-list.php?area=chubu">
                        <img src="assets/img/shop/all_cyubu_sp.png" class="d-lg-none" alt="中部">
                        <img src="assets/img/shop/all_cyubu_pc.png" class="d-none d-lg-block" alt="中部">
                    </a>
                </div>
            </div>
            <div class="area-map_3 align-self-end">
                <div class="all_kinki">
                    <a href="./shop-list.php?area=kinki">
                        <img src="assets/img/shop/all_kinki_sp.png" class="d-lg-none" alt="近畿">
                        <img src="assets/img/shop/all_kinki_pc.png" class="d-none d-lg-block" alt="近畿">
                    </a>
                </div>
            </div>
            <div class="area-map_4 align-self-end">
                <div>
                    <a href="./shop-list.php?area=chuugoku">
                        <img src="assets/img/shop/all_cyugoku_sp.png" class="d-lg-none" alt="中国">
                        <img src="assets/img/shop/all_cyugoku_pc.png" class="d-none d-lg-block" alt="中国">
                    </a>
                    <a href="./shop-list.php?area=shikoku">
                        <img src="assets/img/shop/all_shikoku_sp.png" class="area-map_shikoku d-lg-none" alt="四国">
                        <img src="assets/img/shop/all_shikoku_pc.png" class="area-map_shikoku d-none d-lg-block" alt="四国">
                    </a>
                </div>
            </div>
            <div class="area-map_5 align-self-end">
                <div class="all_kyusyu">
                    <a href="./shop-list.php?area=kyushu">
                        <img src="assets/img/shop/all_kyusyu_sp.png" class="d-lg-none" alt="九州・沖縄">
                        <img src="assets/img/shop/all_kyusyu_pc.png" class="d-none d-lg-block" alt="九州・沖縄">
                    </a>
                </div>
            </div>
        </div>
        <div class="area-map_list mx-auto mb-2" v-for="(area, key) in shopList" :key="key">
            <div class="d-flex flex-wrap flex-lg-nowrap align-items-baseline">
                <h3 class="text-default font-weight-bold mb-2 mb-lg-0" style="width: 7.5em;"><a :href="'./shop-list.php?area=' + key">{{ area.name }}</a></h3>
                <ul class="mb-3 mb-lg-0">
                    <li v-for="(prefecture) in area.prefectures" :key="prefecture.id" class="d-inline-block mr-3 mr-lg-4">
                        <a v-if="prefecture.shops.length > 0" :href="'./shop-list.php?area=' + key + '&pref=pref-' + prefecture.id">{{ prefecture.name }}</a>
                        <span v-else>{{ prefecture.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="small pt-5 text-center">【営業時間は状況により急遽変更する場合がございます】</div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                shopList: [],
            }
        },
        mounted() {
            axios.get('./data/shop.json')
                .then(response => (this.shopList = response.data));
        },
    }
</script>