<template>
    <div>
        <div>
            <div v-if="area == 'hokkaido'" class="mb-5">
                <h3 class="text-lss font-weight-bold text-center letter-spacing-200 mb-5">北海道・東北の店舗一覧</h3>
                <div class="area-1 mx-auto">
                    <div class="w-100">
                        <a href="shop-list.php?area=hokkaido&pref=pref-01">
                            <img src="assets/img/shop/area_1-hokkaido.png" alt="北海道">
                        </a>
                    </div>
                    <div class="area_1_small">
                        <a href="shop-list.php?area=hokkaido&pref=pref-02">
                            <img src="assets/img/shop/area_1-aomori.png" alt="青森県">
                        </a>
                    </div>
                    <div class="d-flex justify-content-between area_1_small">
                        <div>
                            <a href="shop-list.php?area=hokkaido&pref=pref-05">
                                <img src="assets/img/shop/area_1-akita.png" alt="秋田県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=hokkaido&pref=pref-03">
                                <img src="assets/img/shop/area_1-iwate.png" alt="岩手県">
                            </a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between area_1_small">
                        <div>
                            <a href="shop-list.php?area=hokkaido&pref=pref-06">
                                <img src="assets/img/shop/area_1-yamagata.png" alt="山形県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=hokkaido&pref=pref-04">
                                <img src="assets/img/shop/area_1-miyagi.png" alt="宮城県">
                            </a>
                        </div>
                    </div>
                    <div class="area_1_small">
                        <a href="shop-list.php?area=hokkaido&pref=pref-07">
                            <img src="assets/img/shop/area_1-fukushima.png" alt="福島県">
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="area == 'kento'" class="mb-5">
                <h3 class="text-lss font-weight-bold text-center letter-spacing-200 mb-5">関東の店舗一覧</h3>
                <div class="area-2 mx-auto">
                    <div class="d-flex justify-content-between">
                        <div>
                            <a href="shop-list.php?area=kento&pref=pref-10">
                                <img src="assets/img/shop/area_2-gunma.png" alt="群馬県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=kento&pref=pref-09">
                                <img src="assets/img/shop/area_2-tochigi.png" alt="栃木県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=kento&pref=pref-08">
                                <img src="assets/img/shop/area_2-ibaragi.png" alt="茨城県">
                            </a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <div>
                                <a href="shop-list.php?area=kento&pref=pref-11">
                                    <img src="assets/img/shop/area_2-saitama.png" alt="埼玉県">
                                </a>
                            </div>
                            <div>
                                <a href="shop-list.php?area=kento&pref=pref-13">
                                    <img src="assets/img/shop/area_2-tokyo.png" alt="東京都">
                                </a>
                            </div>
                            <div style="width: 91%">
                                <a href="shop-list.php?area=kento&pref=pref-14">
                                    <img src="assets/img/shop/area_2-kanagawa.png" alt="神奈川県">
                                </a>
                            </div>
                        </div>
                        <div>
                            <div>
                                <a href="shop-list.php?area=kento&pref=pref-12">
                                    <img src="assets/img/shop/area_2-chiba.png" alt="千葉県">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="area == 'chubu'" class="mb-5">
                <h3 class="text-lss font-weight-bold text-center letter-spacing-200 mb-5">中部の店舗一覧</h3>
                <div class="area-3 mx-auto">
                    <div class="d-flex justify-content-between align-items-end">
                        <div>
                            <a href="shop-list.php?area=chubu&pref=pref-18">
                                <img src="assets/img/shop/area_3-fukui.png" alt="福井県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=chubu&pref=pref-17">
                                <img src="assets/img/shop/area_3-ishikawa.png" alt="石川県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=chubu&pref=pref-16">
                                <img src="assets/img/shop/area_3-toyama.png" alt="富山県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=chubu&pref=pref-15">
                                <img src="assets/img/shop/area_3-niigata.png" alt="新潟県">
                            </a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <a href="shop-list.php?area=chubu&pref=pref-21">
                                <img src="assets/img/shop/area_3-gifu.png" alt="岐阜県">
                            </a>
                        </div>
                        <div class="d-flex flex-column">
                            <div>
                                <a href="shop-list.php?area=chubu&pref=pref-20">
                                    <img src="assets/img/shop/area_3-nagano.png" alt="長野県">
                                </a>
                            </div>
                            <div>
                                <a href="shop-list.php?area=chubu&pref=pref-19">
                                    <img src="assets/img/shop/area_3-yamanashi.png" alt="山梨県">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <a href="shop-list.php?area=chubu&pref=pref-23">
                                <img src="assets/img/shop/area_3-aichi.png" alt="愛知県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=chubu&pref=pref-22">
                                <img src="assets/img/shop/area_3-shizuoka.png" alt="静岡県">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="area == 'kinki'" class="mb-5">
                <h3 class="text-lss font-weight-bold text-center letter-spacing-200 mb-5">近畿の店舗一覧</h3>
                <div class="area-4 mx-auto">
                    <div class="d-flex justify-content-between">
                        <div>
                            <a href="shop-list.php?area=kinki&pref=pref-28">
                                <img src="assets/img/shop/area_4-hyogo.png" alt="兵庫県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=kinki&pref=pref-26">
                                <img src="assets/img/shop/area_4-kyoto.png" alt="京都府">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=kinki&pref=pref-25">
                                <img src="assets/img/shop/area_4-shiga.png" alt="滋賀県">
                            </a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <a href="shop-list.php?area=kinki&pref=pref-27">
                                        <img src="assets/img/shop/area_4-osaka.png" alt="大阪府">
                                    </a>
                                </div>
                                <div>
                                    <a href="shop-list.php?area=kinki&pref=pref-29">
                                        <img src="assets/img/shop/area_4-nara.png" alt="奈良県">
                                    </a>
                                </div>
                            </div>
                            <div>
                                <a href="shop-list.php?area=kinki&pref=pref-30">
                                    <img src="assets/img/shop/area_4-wakayama.png" alt="和歌山県">
                                </a>
                            </div>
                        </div>
                        <div>
                            <a href="shop-list.php?area=kinki&pref=pref-24">
                                <img src="assets/img/shop/area_4-mie.png" alt="三重県">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="area == 'chuugoku'" class="mb-5">
                <h3 class="text-lss font-weight-bold text-center letter-spacing-200 mb-5">中国の店舗一覧</h3>
                <div class="area-5 mx-auto">
                    <div class="d-flex justify-content-between">
                        <div>
                            <a href="shop-list.php?area=chuugoku&pref=pref-35">
                                <img src="assets/img/shop/area_5-yamaguchi.png" alt="山口県">
                            </a>
                        </div>
                        <div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <a href="shop-list.php?area=chuugoku&pref=pref-32">
                                        <img src="assets/img/shop/area_5-simane.png" alt="島根県">
                                    </a>
                                </div>
                                <div>
                                    <a href="shop-list.php?area=chuugoku&pref=pref-31">
                                        <img src="assets/img/shop/area_5-tottori.png" alt="鳥取県">
                                    </a>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <a href="shop-list.php?area=chuugoku&pref=pref-34">
                                        <img src="assets/img/shop/area_5-hiroshima.png" alt="広島県">
                                    </a>
                                </div>
                                <div>
                                    <a href="shop-list.php?area=chuugoku&pref=pref-33">
                                        <img src="assets/img/shop/area_5-okayama.png" alt="岡山県">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="area == 'shikoku'" class="mb-5">
                <h3 class="text-lss font-weight-bold text-center letter-spacing-200 mb-5">四国の店舗一覧</h3>
                <div class="area-6 mx-auto">
                    <div class="d-flex justify-content-between">
                        <div>
                            <a href="shop-list.php?area=shikoku&pref=pref-38">
                                <img src="assets/img/shop/area_6-ehime.png" alt="愛媛県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=shikoku&pref=pref-37">
                                <img src="assets/img/shop/area_6-kagawa.png" alt="香川県">
                            </a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <a href="shop-list.php?area=shikoku&pref=pref-39">
                                <img src="assets/img/shop/area_6-kochi.png" alt="高知県">
                            </a>
                        </div>
                        <div>
                            <a href="shop-list.php?area=shikoku&pref=pref-36">
                                <img src="assets/img/shop/area_6-tokushima.png" alt="徳島県">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="area == 'kyushu'" class="mb-5">
                <h3 class="text-lss font-weight-bold text-center letter-spacing-200 mb-5">九州・沖縄の店舗一覧</h3>
                <div class="area-7 mx-auto d-flex justify-content-between align-items-end">
                    <div>
                        <div>
                            <a href="#">
                                <img src="assets/img/shop/area_7-okinawa.png" alt="沖縄県">
                            </a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <a href="shop-list.php?area=kyushu&pref=pref-42">
                                <img src="assets/img/shop/area_7-nagasaki.png" alt="長崎県">
                            </a>
                        </div>
                        <div class="d-flex flex-column">
                            <div>
                                <a href="shop-list.php?area=kyushu&pref=pref-41">
                                    <img src="assets/img/shop/area_7-saga.png" alt="佐賀県">
                                </a>
                            </div>
                            <div>
                                <a href="shop-list.php?area=kyushu&pref=pref-43">
                                    <img src="assets/img/shop/area_7-kumamoto.png" alt="熊本県">
                                </a>
                            </div>
                            <div>
                                <a href="shop-list.php?area=kyushu&pref=pref-46">
                                    <img src="assets/img/shop/area_7-kagoshima.png" alt="鹿児島県">
                                </a>
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <div>
                                <a href="shop-list.php?area=kyushu&pref=pref-40">
                                    <img src="assets/img/shop/area_7-fukuoka.png" alt="福岡県">
                                </a>
                            </div>
                            <div>
                                <a href="shop-list.php?area=kyushu&pref=pref-44">
                                    <img src="assets/img/shop/area_7-oita.png" alt="大分県">
                                </a>
                            </div>
                            <div>
                                <a href="shop-list.php?area=kyushu&pref=pref-45">
                                    <img src="assets/img/shop/area_7-miyazaki.png" alt="宮崎県">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span v-for="(area, key) in shopList[area]" :key="key">
            <div v-for="(pref) in area" :key="pref.id" :id="'pref-' + pref.id">
                <h4 class="text-center text-lss font-weight-bold mb-4">{{ pref.name }}</h4>
                <ul class="mb-5">
                    <li v-for="(shop, sKey) in pref.shops" :key="'shop-' + sKey" class="area-list_block d-flex flex-column flex-lg-row bg-white p-3 p-lg-4 mx-auto mb-4">
                        <div class="area-list_info mr-0 mr-lg-5 mb-4 mb-lg-0">
                            <h5 class="text-m font-weight-bold">
                                {{ shop.name }}
                                <span v-if="shop.closed != null" class="text-accent">{{ shop.closed }}</span>
                            </h5>
                            <div>{{ shop.address }}</div>
                            <a v-if="shop.map != null" :href="shop.map" class="text-black">&gt; Googleマップ</a>
                        </div>
                        <div class="area-list_status">
                            <div class="d-flex mb-2">
                                <div class="mr-4" style="min-width: 36%;">
                                    <div v-if="shop.badges.can_delivery == true" class="shopicon shopicon_delivery">デリバリー</div>
                                </div>
                                <div class="text-left">
                                    <a v-if="shop.orders.uber != null" :href="shop.orders.uber" class="d-inline-block mr-2 mb-2" target="_blank" rel="noopener noreferrer"><img src="assets/img/common/icon_ubereats.png" alt="UberEats" class="btn_ubereats"></a>
                                    <a v-if="shop.orders.demaekan != null" :href="shop.orders.demaekan" class="d-inline-block mr-2 mb-2" target="_blank" rel="noopener noreferrer"><img src="assets/img/common/icon_demaekan.png" alt="UberEats" class="btn_demaekan"></a>
                                    <a v-if="shop.orders.menu != null" :href="shop.orders.menu" class="d-inline-block mr-2 mb-2" target="_blank" rel="noopener noreferrer"><img src="assets/img/common/icon_menu.png" alt="menu" class="btn_menu"></a>
                                    <a v-if="shop.orders.didifood != null" :href="shop.orders.didifood" class="d-inline-block mr-2 mb-2" target="_blank" rel="noopener noreferrer"><img src="assets/img/common/icon_didifood.png" alt="didifood" class="btn_didifood"></a>
                                    <a v-if="shop.orders.wolt != null" :href="shop.orders.wolt" class="d-inline-block mr-2 mb-2" target="_blank" rel="noopener noreferrer"><img src="assets/img/common/icon_wolt.png" alt="wolt" class="btn_wolt"></a>
                                </div>
                            </div>
                            <div class="d-flex mb-2">
                                <div v-if="shop.badges.can_takeout == true" class="shopicon shopicon_takeout mr-4">テイクアウト</div>
                                <div v-if="shop.badges.can_eat_in == true" class="shopicon shopicon_eatin">イートイン</div>
                            </div>
                            <div v-if="shop.badges.for_business == true" class="shopicon shopicon_office">事業所向けデリバリー</div>
                        </div>
                    </li>
                </ul>
                <p v-if="Object(pref.shops).length == 0" class="text-center mb-5">現在営業中の店舗がありません。</p>
            </div>
        </span>
        <div class="text-center pt-3"><a href="./index.php#shop" class="text-black">&gt; 全国一覧に戻る</a></div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                shopList: {},
                area: null
            }
        },
        created(){
            const urlParams = new URLSearchParams(window.location.search);
            this.area = urlParams.get('area');

            axios.get('./data/shop.json')
                .then(response => (this.shopList = response.data));
        },
        updated() {
            const urlParams = new URLSearchParams(window.location.search);
            const selectedPref = urlParams.get('pref');
            let element = document.getElementById(selectedPref);
            if(element != null) {
                element.scrollIntoView();
            }
        },
    }
</script>